<template>
  <div style="height: 500px">
    <search-case
      :searchData="searchData"
      @inquireBtnClick="inquireBtnClick"
    ></search-case>

    <div style="padding: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column
          prop="residue"
          sortable
          label="系统库存"
        ></el-table-column>
        <el-table-column sortable label="实际库存">
          <template slot-scope="scope">
            <el-input
              v-model="scope.row.practical"
              type="number"
              style="width: 50%"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column sortable label="差异">
          <template slot-scope="scope">
            {{ Number(scope.row.practical) - Number(scope.row.residue) }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
            <span style="margin: 0 10px"></span>
            <i
              class="el-icon-search poiner"
              @click="handleDelete(scope.row)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <div class="tableBottom">
        <span
          >显示第&nbsp;{{
            tableData.length === 0 ? 0 : (page - 1) * length + 1
          }}&nbsp;至&nbsp;{{
            (page - 1) * length + tableData.length
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchData: {
        firstSelectSpan: "仓库店铺：",
        isFirstSelect: true,
        firstOptiones: [],
        firstSelectPlaceholder: "请选择",
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
      },

      tableData: [],
      isResult: false,
      tableFinshNum: 0,
      commercial_id: "",
      page: 1,
      length: 10,
      operator: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.operator =
      localStorage.getItem("user_id") || sessionStorage.getItem("user_id");
    this.getShopList();
    this.getTabList();
  },
  computed: {},
  methods: {
    async handleEdit(row) {
      console.log(row);
      if (row.practical == row.residue) {
        this.$message.error("库存一致不能修改");
        return;
      }
      const res = await this.$http.post(
        "/inventory/empty_barrel/residue_edit",
        this.$qs.stringify({
          id: row.id,
          number: row.practical,
          operator: this.operator,
        })
      );
      this.$message({ type: res.data.status, message: res.data.msg });
      this.getTabList();
    },
    handleDelete() {},
    async inquireBtnClick(data) {
      const res = await this.$http.post(
        "/inventory/empty_barrel/residue_search",
        this.$qs.stringify({
          search: data.firstSelect,
          commercial_id: data.inquireValue,
        })
      );
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
    async getShopList() {
      const res = await this.$http.post("/system/shop/lst", {
        commercial_id: this.commercial_id,
      });
      this.searchData.firstOptiones = res.data.data.map((item) => {
        return { id: item.id, label: item.shop_name };
      });
    },
    async getTabList() {
      const res = await this.$http.post(
        "/inventory/empty_barrel/residue_list",
        this.$qs.stringify({
          commercial_id: this.commercial_id,
          currentPage: this.page,
          currentLength: this.length,
        })
      );
      console.log(res);
      this.tableData = res.data.data.map((item) => {
        return {
          name: item.name,
          residue: item.residue,
          practical: item.residue,
          id: item.id,
        };
      });
      this.tableFinshNum = res.data.count;
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .searchcase {
  width: 120%;
  padding: 0 20px !important;
  border: 0 !important;
}
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
