<template>
  <div>
    <div class="row">
      <el-input v-model="inpVal" placeholder="请输入要查询的内容" />
      <el-button icon="el-icon-search" type="success" @click="searchClick">查询</el-button><el-button icon="el-icon-search"
        type="success" @click="importClick">导入</el-button>
    </div>
    <search-case :searchData="searchData"></search-case>
    <div style="padding: 20px">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
        @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column type="selection" width="44"> </el-table-column>
        <el-table-column prop="id" sortable label="ID"></el-table-column>
        <el-table-column prop="column_name" label="名称"></el-table-column>
      </el-table>
      <div class="tableBottom">
        <span style="">显示第&nbsp;1&nbsp;至&nbsp;{{
          tableFinshNum
        }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>

        <div style="display: flex; justify-content: flex-end; align-items: center">
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchData: {
        QueryContent: "",
        searchPlace: "输入要查询的内容",
        searchBtn1: true,
        daoruType: "success",
      },

      tableData: [],
      multipleSelection: [],
      tableFinshNum: 0,
      inpVal: "",
      page: 1,
      length: 10,
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    async searchClick() {
      const res = await this.$http.post(
        "/inventory/empty_barrel/search",
        this.$qs.stringify({
          search: this.inpVal,
        })
      );
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
    async importClick() {
      this.$emit("importClick");
      const arr = this.multipleSelection.map((item) => {
        return {
          bid: String(item.id),
          name: item.column_name,
          commercial_id: String(item.commercial_id),
        };
      });
      const res = await this.$http.post("/inventory/empty_barrel/add", {
        barrel_arr: arr,
      });
      console.log(JSON.stringify(arr));
      this.$message({ type: res.data.status, message: res.data.msg });
      this.$emit("close");
    },
    async getTabList() {
      const res = await this.$http.post("/inventory/empty_barrel/barrel_list", {
        currentPage: this.page,
        currentLength: this.length,
        commercial_id: this.commercial_id
      });
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .searchcase {
  padding: 0 20px !important;
  border: 0 !important;
}

.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

.row {
  display: flex;
  align-items: center;
  padding: 0 20px;

  .el-input {
    width: 30%;
    margin-right: 10px;
  }
}

/deep/ .cell {
  padding: 0 !important;
}
</style>
