<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="editShow"
      width="50%"
      :before-close="editClose"
    >
      <el-form ref="form" :model="form" :rules="rules" label-width="100px">
        <el-form-item label="桶品牌名：" prop="name">
          <div>{{ name }}</div>
        </el-form-item>
        <el-form-item label="操作：" prop="name">
          <el-radio-group v-model="form.operate">
            <el-radio :label="1">加</el-radio>
            <el-radio :label="0">减</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="数量：" prop="number">
          <el-input v-model="form.number" type="number"></el-input>
        </el-form-item>
        <el-form-item label="事由：" prop="reason">
          <el-select v-model="form.reason" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="备注：" prop="notes">
          <el-input v-model="form.notes" type="textarea" rows="4"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="submit('form')" type="primary"> 提交 </el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: "提示",
      required: true,
    },
    editShow: {
      type: Boolean,
      default: false,
      required: true,
    },
    data: {
      type: Object,
      default: {},
      required: true,
    },
  },
  data() {
    return {
      name: "",
      form: {
        id: "",
        number: "",
        reason: "",
        notes: "",
        operate: 1,
        operator: "",
        commercial_id: "",
      },
      rules: {
        number: [{ required: true, message: "请填入必填项", trigger: "blur" }],
        reason: [{ required: true, message: "请填入必填项", trigger: "blur" }],
        notes: [{ required: true, message: "请填入必填项", trigger: "blur" }],
      },
      options: [
        { label: "手动入库", value: 1 },
        { label: "进货入库", value: 2 },
        { label: "退货", value: 3 },
        { label: "特殊情况入库", value: 4 },
      ],
    };
  },
  mounted() {
    this.form.operator =
      localStorage.getItem("user_id") || sessionStorage.getItem("user_id");
    this.form.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    if (this.$props.data) {
      const data = this.$props.data;
      this.name = data.name;
      this.form.id = data.id;
    }
  },
  computed: {},
  methods: {
    editClose() {
      this.$emit("editClose");
    },
    submit(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await this.$http.post(
            "/inventory/empty_barrel/scheduling",
            this.$qs.stringify(this.form)
          );
          this.$message({ type: res.data.status, message: res.data.msg });
          this.editClose();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped></style>
