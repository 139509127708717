<template>
  <div style="height: 500px">
    <search-case
      :searchData="searchData"
      @inquireBtnClick="inquireBtnClick"
    ></search-case>

    <div style="padding: 20px">
      <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        border
        style="width: 100%"
      >
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column
          prop="initial_num"
          sortable
          label="期初库存"
        ></el-table-column>
        <el-table-column
          prop="warehousing"
          sortable
          label="入库"
        ></el-table-column>
        <el-table-column
          prop="outbound"
          sortable
          label="出库"
        ></el-table-column>
        <el-table-column prop="residue" sortable label="结存"></el-table-column>
      </el-table>
      <div class="tableBottom">
        <span
          >显示第&nbsp;1&nbsp;至&nbsp;{{
            tableFinshNum
          }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
        >
        <div
          style="display: flex; justify-content: flex-end; align-items: center"
        >
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination
            background
            layout=" pager,slot"
            :total="1"
          ></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      searchData: {
        date: "",
        firstSelectSpan: "仓库店铺：",
        isFirstSelect: true,
        firstOptiones: [],
        firstSelectPlaceholder: "请选择",
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
      },
      tableData: [],
      tableFinshNum: 0,
      length: 10,
      page: 1,
      commercial_id: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
    this.getShopList();
  },
  computed: {},
  methods: {
    async inquireBtnClick(data) {
      const res = await this.$http.post(
        "/inventory/empty_barrel/balance_search",
        this.$qs.stringify({
          commercial_id: data.firstSelect,
          search: data.inquireValue,
          firstTime: data.dateRange[0],
          lastTime: data.dateRange[1],
        })
      );
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
    async getShopList() {
      const res = await this.$http.post("/system/shop/lst", {
        commercial_id: this.commercial_id,
      });
      this.searchData.firstOptiones = res.data.data.map((item) => {
        return { id: item.id, label: item.shop_name };
      });
    },
    async getTabList() {
      const res = await this.$http.post(
        "/inventory/empty_barrel/balance_lst",
        this.$qs.stringify({
          commercial_id: this.commercial_id,
          currentPage: this.page,
          currentLength: this.length,
        })
      );
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
  },
};
</script>
<style scoped lang="less">
/deep/ .searchcase {
  width: 120%;
  padding: 0 20px !important;
  border: 0 !important;
}
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
