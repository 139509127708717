<template>
  <div v-loading="show" element-loading-background="rgba(0, 0, 0, 0.8)">
    <bread-crumb :crumbData="['空桶库存']" :chooseCrumbData="2" @close="hide"></bread-crumb>

    <search-case :searchData="searchData" @inquireBtnClick="inquireBtnClick"></search-case>

    <div style="
        padding: 10px 20px;
        border-bottom: 1px solid #eee;
        display: flex;
        align-items: center;
      ">
      <div style="margin-right: 5px">
        <el-button type="danger" size="mini" @click="dialogVisible1 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">导入空桶</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="danger" size="mini" @click="batchClick">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">批量删除</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="dialogVisible2 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">账面结存</span>
          </div>
        </el-button>
      </div>
      <div style="margin-right: 5px">
        <el-button type="primary" size="mini" @click="dialogVisible3 = true">
          <div style="display: flex; align-items: center; font-size: 14px">
            <img style="width: 16px; height: 16px" :src="require('@/assets/iconImg/logo.png')" />
            <span style="margin-left: 3px">库存盘点</span>
          </div>
        </el-button>
      </div>
    </div>

    <div style="padding: 20px">
      <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" border
        @selection-change="handleSelectionChange" style="width: 100%">
        <el-table-column type="selection" width="44"> </el-table-column>
        <el-table-column prop="id" sortable label="ID"></el-table-column>
        <el-table-column prop="name" label="名称"></el-table-column>
        <el-table-column prop="residue" label="库存"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
            <span style="margin: 0 10px"></span>
            <i class="el-icon-search poiner" @click="handleDelete(scope.row)"></i>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="tableBottom">
        <span style="">显示第&nbsp;{{
          tableData.length == 0 ? 0 : (page - 1) * length + 1
        }}&nbsp;至&nbsp;{{
  (page - 1) * length + tableData.length
}}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span>
        <div style="display: flex; justify-content: flex-end; align-items: center">
          <el-button>首页</el-button>
          <el-button>上页</el-button>
          <el-pagination background layout=" pager,slot" :total="1"></el-pagination>
          <el-button>下页</el-button>
          <el-button>末页</el-button>
        </div>
      </div> -->
      <pagination :handleQuery="getTabList" :currentPage="page" :currentLength="length" :total="tableFinshNum">
      </pagination>
    </div>

    <el-dialog title="空桶资料" :visible.sync="dialogVisible1" width="70%" :before-close="handleClose">
      <add-empty-barrel v-if="dialogVisible1" @close="close"></add-empty-barrel>
    </el-dialog>

    <el-dialog title="账面结存" :visible.sync="dialogVisible2" width="70%" :before-close="handleClose">
      <book-balance v-if="dialogVisible2"></book-balance>
    </el-dialog>

    <el-dialog title="库存盘点" :visible.sync="dialogVisible3" width="70%" :before-close="handleClose">
      <stock-taking v-if="dialogVisible3"></stock-taking>
    </el-dialog>
    <EditDialog :edit-show="editShow" title="出入库" @editClose="editClose" :data="editData" v-if="editShow" />
    <TabDialog title="库存日志" :tabShow="tabShow" @tabClose="tabClose" :rowId="rowId"
      url="/inventory/empty_barrel/barrel_lst" v-if="tabShow" />
  </div>
</template>
<script>
import pagination from "@/components/pagination/index.vue";
import addEmptyBarrel from "./childrens/addEmptyBarrel.vue";
import bookBalance from "./childrens/bookBalance.vue";
import stockTaking from "./childrens/stockTaking.vue";
import EditDialog from "../components/editDialog.vue";
import TabDialog from "../components/tabDialog.vue";
export default {
  components: {
    pagination,
    addEmptyBarrel,
    bookBalance,
    stockTaking,
    EditDialog,
    TabDialog,
  },
  props: ['onClose'],
  data() {
    return {
      hide: this.onClose || this.close,
      searchData: {
        secondSelectSpan: "仓库店铺:",
        isSecondSelect: true,
        secondSelectPlaceholder: "请选择",
        secondOptiones: [],
        inquirePlaceholder: "输入要查询的内容",
        isInquireValue: true,
        isInquireBtn: true,
      },
      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,
      dialogVisible1: false,
      dialogVisible2: false,
      dialogVisible3: false,
      page: 1,
      length: 10,
      commercial_id: "",
      editShow: false,
      editData: "",
      tabShow: false,
      rowId: "",
      show: false,
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
    this.getStoreList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleEdit(row) {
      this.editData = row;
      this.editShow = true;
    },
    async handleDelete(row) {
      this.rowId = row.id;
      this.tabShow = true;
    },
    tabClose() {
      this.tabShow = false;
    },
    close() {
      this.dialogVisible1 = false;
      this.getTabList();
    },
    getTabList(page = 1) {
      this.$http
        .post(
          "/inventory/empty_barrel/lst",
          this.$qs.stringify({
            currentPage: page,
            currentLength: this.length,
            commercial_id: this.commercial_id,
          })
        )
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(() => {
          done();
        })
        .catch(() => { });
    },
    batchClick() {
      if (this.multipleSelection.length == 0) return;
      let arr = this.multipleSelection.map((item) => {
        return item.id;
      });
      arr = arr.join(",");
      this.$http.post(
        "/inventory/empty_barrel/delAll",
        this.$qs.stringify({
          idArr: arr,
        })
      );
      this.getTabList();
    },
    async inquireBtnClick(data) {
      console.log(data);
      const res = await this.$http.post(
        "/inventory/empty_barrel/lst_search",
        {
          id: data.secondSelect,
          commercial_id: this.commercial_id,
          search: data.inquireValue,
          currentPage: this.page,
          currentLength: this.length
        }
      );
      this.tableData = res.data.data;
      this.tableFinshNum = res.data.count;
    },
    async getStoreList() {
      const res = await this.$http.post("/Client/commercial_owner/lst_1", {
        commercial_id: this.commercial_id,

      });
      this.searchData.secondOptiones = res.data.data;
    },
    editClose() {
      this.editShow = false;
      this.getTabList();
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}

/deep/ .cell {
  padding: 0 !important;
}

/deep/ .el-dialog__header {
  padding: 10px !important;
}

/deep/ .el-dialog__title {
  font-size: 16px !important;
}

/deep/ .el-dialog__body {
  padding: 10px !important;
}
</style>
